import React from "react";

const checkBoxValues = [
  {
    label: "Yes",
    value: "yes",
  },
  {
    label: "No",
    value: "no",
  },
  {
    label: "Not Applicable",
    value: "notApplicable",
  },
];

export default function CTextField({
  label,
  name,
  value,
  onChange,
  error = "",
  type = "text",
  rows = 1,
  placeholder = "",
  className = "",
  width = "w-full",
  qIndex = "",
}) {
  return (
    <div className={`flex flex-col ${width} mb-4 `}>
      {label && (
        <div className="flex flex-row  gap-1 mb-1">
          <span className="text-base font-semibold">
            {qIndex ? ` ${qIndex})  ` : ""}
          </span>
          <label className={`pt-1 text-[12px] font-semibold mb-1 ${className}`}>
            {` ${label} `}
          </label>
        </div>
      )}
      {type === "checkbox" ? (
        <div className="flex flex-row items-center  space-x-8 mt-1">
          {checkBoxValues.map((checkBoxValue, index) => (
            <div key={index} className="flex items-center">
              <input
                type="checkbox"
                name={name}
                value={checkBoxValue.value}
                checked={value === checkBoxValue.value}
                onChange={onChange}
                className="ml-8 mr-2 h-4 w-4"
              />
              <label className="text-[12px] font-semibold">
                {checkBoxValue.label}
              </label>
            </div>
          ))}
        </div>
      ) : (
        <input
          type={type}
          rows={rows}
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={` px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400 ${className}`}
        />
      )}
      {error && (
        <span className="text-red-500 text-xs mt-1 pl-2">{` ${error} `}</span>
      )}
    </div>
  );
}
