import Container from "react-bootstrap/Container";
import { useSelector, useDispatch } from "react-redux";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { logout } from "../../actions/userAction";
import logo from "../../assets/Bizpole_logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "../../App.css";

function Header() {
  const { isAuthenticated, user } = useSelector((state) => state.authState);
  const dispatch = useDispatch();

  const logoutHandler = async () => {
    await dispatch(logout);
  };

  return (
    <Navbar expand="lg" className="NavBar_styles no-print fixed">
      <Container className="no-print">
        <Navbar.Brand>
          <Link to={"/"}>
            <img src={logo} alt="logo" className="w-24" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          {isAuthenticated ? (
            <Nav
              className="ms-auto my-auto my-2 my-lg-2"
              style={{ maxHeight: "150px" }}
              navbarScroll
            >
              {/* <Link className="nav-link" to={"/"}>
                Home
              </Link> */}
              <Link className="nav-link" to={"/dashboard"}>
                DashBoard
              </Link>
              <Link className="nav-link" to={"/add-new"}>
                Create
              </Link>
              {/* <Link className="nav-link" to={"/new"}>
                Create
              </Link> */}
              <Dropdown>
                <Dropdown.Toggle
                  style={{ minWidth: "100px" }}
                  id="dropdown-basic"
                >
                  <FontAwesomeIcon icon={faUser} className="px-2" />
                  {user.name && user.name}
                </Dropdown.Toggle>
                <Dropdown.Menu className="text-center">
                  <Dropdown.Item onClick={logoutHandler}>
                    Logout
                    <FontAwesomeIcon
                      icon={faArrowRightFromBracket}
                      className="px-2"
                    />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          ) : (
            <></>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
