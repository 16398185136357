import React from "react";
import { Loader2 } from "lucide-react";

export default function CLoader() {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm z-50">
      <div className="flex flex-row gap-2 items-center bg-white px-6 rounded-2xl shadow-md">
        <Loader2 className="w-8 h-8 text-primaryClr animate-spin" />
        <p className="text-primaryClr mt-3 text-lg font-semibold">Loading...</p>
      </div>
    </div>
  );
}
