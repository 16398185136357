import { createAsyncThunk } from "@reduxjs/toolkit";
import { ApiEndPoints } from "../constants/apiEndPoints";
import { apiRequest, apiType } from "../services/apiRequestFun";

//Document Upload
export const uploadDocument = createAsyncThunk(
  "uploadDocument",
  async (payload) => {
    // Add your upload logic here
    const response = await apiRequest({
      apiType: apiType.POST,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.UPLOAD_DOCUMENT}`,
      body: payload,
      isFormData: true,
    });
    return response;
  }
);

//ADD NEW CHECKLIST
export const addNewCheckList = createAsyncThunk(
  "addNewCheckList",
  async (payload) => {
    const response = await apiRequest({
      apiType: apiType.POST,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.ADD_NEW_CHECKLIST}`,
      body: payload,
    });
    return response;
  }
);

//FETCH ALL CHECKLISTS
export const fetchAllCheckLists = createAsyncThunk(
  "fetchAllCheckLists",
  async ({ page = 1, search = "" }) => {
    const response = await apiRequest({
      apiType: apiType.GET,
      url: `${ApiEndPoints.BASE_URL}${
        ApiEndPoints.FETCH_ALL_CHECKLISTS
      }?page=${page.toString()}&companyName=${search}`,
    });

    return response;
  }
);

//FETCH CHECKLIST BY ID
export const fetchCheckListById = createAsyncThunk(
  "fetchCheckListById",
  async (id) => {
    const response = await apiRequest({
      apiType: apiType.GET,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.FETCH_CHECKLIST_BY_ID}/${id}`,
    });

    return response;
  }
);

// GET EDIT CHECKLIST DATA
export const getEditCheckListData = createAsyncThunk(
  "getEditCheckListData",
  async (id) => {
    const response = await apiRequest({
      apiType: apiType.GET,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.FETCH_CHECKLIST_BY_ID}/${id}`,
    });
    return response;
  }
);

//EDIT CHECKLIST
export const editCheckList = createAsyncThunk(
  "editCheckList",
  async ({ id, payload }) => {
    console.log("payload 111", payload);
    const response = await apiRequest({
      apiType: apiType.PUT,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.EDIT_CHECKLIST}/${id}`,
      body: payload,
    });
    return response;
  }
);

// DELETE CHECKLIST
export const deleteCheckList = createAsyncThunk(
  "deleteCheckList",
  async ({ id, updatedBy }) => {
    const response = await apiRequest({
      apiType: apiType.DELETE,
      url: `${ApiEndPoints.BASE_URL}${ApiEndPoints.DELETE_CHECKLIST}/${id}?updatedBy=${updatedBy}`,
    });
    return response;
  }
);
