import { createSlice } from "@reduxjs/toolkit";

const checkListSlice = createSlice({
  name: "checkList",
  initialState: {
    loading: false,
    checkList: {},
    isProductCreated: false,
    isCheckListDeleted: false,
    isCheckListUpdated: false,
  },
  reducers: {
    checkListRequest(state, action) {
      return {
        loading: true,
      };
    },
    checkListSuccess(state, action) {
      return {
        loading: false,
        checkList: action.payload.checkList,
      };
    },
    checkListFail(state, action) {
      return {
        loading: false,
        error: action.payload,
      };
    },
    newCheckListRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    newCheckListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        checkList: action.payload.auditChecklist,
        isProductCreated: true,
      };
    },
    newCheckListFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
        isProductCreated: false,
      };
    },
    clearError(state, action) {
      return {
        ...state,
        error: null,
      };
    },
    clearProduct(state, action) {
      return {
        ...state,
        product: {},
      };
    },
    updateCheckListRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    updateCheckListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        checkList: action.payload.auditChecklist,
        isCheckListUpdated: true,
      };
    },
    updateCheckListFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearCheckListUpdated(state, action) {
      return {
        ...state,
        isCheckListUpdate: false,
      };
    },
    deleteCheckListRequest(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteCheckListSuccess(state, action) {
      return {
        ...state,
        loading: false,
        isCheckListDeleted: true,
      };
    },
    deleteCheckListFail(state, action) {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    },
    clearCheckListDeleted(state, action) {
      return {
        ...state,
        isCheckListDeleted: false,
      };
    },
  },
});

const { actions, reducer } = checkListSlice;

export const {
  checkListRequest,
  checkListSuccess,
  checkListFail,
  newCheckListRequest,
  newCheckListSuccess,
  newCheckListFail,
  updateCheckListRequest,
  updateCheckListSuccess,
  updateCheckListFail,
  clearCheckListUpdated,
  clearError,
  deleteCheckListRequest,
  deleteCheckListSuccess,
  deleteCheckListFail,
  clearCheckListDeleted
} = actions;

export default reducer;
