import { createSlice } from "@reduxjs/toolkit";
import { questions } from "../constants/questions";
import { checkListValidation } from "./createCheckListActions";
import {
  uploadDocument,
  addNewCheckList,
  fetchAllCheckLists,
  fetchCheckListById,
  getEditCheckListData,
  editCheckList,
  deleteCheckList,
} from "./createCheckListReducers";
import { toast } from "react-toastify";
import {
  getSectionTitleFromId,
  getQuestionIndexFromId,
  getQuestionLabelFromId,
  getQuestionWidthFromId,
  getSubQuestionLabelFromId,
  getQuestionInputNameFromId,
  getSubQuestionInputNameFromId,
  getSubQuestionWidthFromId,
} from "./createCheckListActions";

const initialState = {
  checkList: [],
  fetchCheckListLoading: false,
  deleteLoading: false,
  isDeleteDialogueOpen: false,
  deleteCheckListId: null,
  previewCheckList: {},
  addCheckListLoading: false,
  docUploadLoading: false,
  checkListQuestions: questions,
  showDocUpload: false,
  uploadDocData: null,
  uploadDocError: null,
  searchQuery: "",
  paginationData: {
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
  },
};

const createCheckListSlice = createSlice({
  name: "createCheckList",
  initialState,
  reducers: {
    // UPDATE INPUT FIELD CHANGES
    updateInputValue: (state, action) => {
      const { sectionId, questionId, isRemark, value, subFieldId } =
        action.payload;
      state.checkListQuestions = state.checkListQuestions.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            questions: section.questions.map((q) => {
              if (q.questionId === questionId) {
                if (subFieldId) {
                  return {
                    ...q,
                    subFields: q.subFields.map((subField) =>
                      subField.subFieldId === subFieldId
                        ? { ...subField, value }
                        : subField
                    ),
                  };
                }
                return {
                  ...q,
                  ...(isRemark ? { remarkValue: value } : { value }),
                };
              }
              return q;
            }),
          };
        }
        return section;
      });
      if (!subFieldId && !isRemark) checkListValidation(state, action);
    },

    // SET SHOW DOC UPLOAD
    setShowDocUpload: (state, action) => {
      state.showDocUpload = action.payload.showDialogue;
      state.uploadDocData = action.payload.uploadDocData;
    },

    //REMOVE DOWNLOAD LINK FROM UPLOAD DOCUMENT DATA
    removeUploadDocumentLink: (state, action) => {
      state.uploadDocData = {
        ...state.uploadDocData,
        documentLink: null,
        documentName: null,
      };

      const { sectionId, questionId } = state.uploadDocData;
      //---------------- REMOVE UPLOAD DOCUMENT LINK FROM CHECKLIST QUESTIONS --------------
      state.checkListQuestions = state.checkListQuestions.map((section) => {
        if (section.sectionId === sectionId) {
          return {
            ...section,
            questions: section.questions.map((q) => {
              if (q.questionId === questionId) {
                return {
                  ...q,
                  documentLink: null,
                  documentName: null,
                };
              }
              return q;
            }),
          };
        }
        return section;
      });
    },

    //VALIDATE CHECKLIST FORM
    validateCheckListForm: (state) => {
      const { checkListQuestions } = state;
      checkListQuestions.forEach((section) => {
        section.questions.forEach((question) => {
          if (question.questionId === 3 && !question.value) {
            question.formError = "Please Enter the value";
          } else {
            question.formError = "";
          }
        });
      });
    },

    // RESET CHECKLIST FORM
    resetCheckListForm: (state) => {
      state.checkListQuestions = questions;
      state.showDocUpload = false;
      state.uploadDocData = null;
      state.uploadDocError = null;
    },
    // SET DELETE DIALOGUE OPEN
    setDeleteDialogueOpen: (state, action) => {
      state.isDeleteDialogueOpen = action.payload;
    },

    // SET DELETE CHECKLIST ID
    setDeleteCheckListId: (state, action) => {
      state.deleteCheckListId = action.payload;
    },

    // SET SEARCH QUERY
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
  },

  extraReducers: (builder) => {
    // UPLOAD DOCUMENT
    builder
      .addCase(uploadDocument.pending, (state) => {
        state.docUploadLoading = true;
      })
      .addCase(uploadDocument.fulfilled, (state, action) => {
        state.docUploadLoading = false;
        const { sectionId, questionId } = state.uploadDocData;
        const res = action.payload;
        if (res.success === true) {
          state.showDocUpload = false;
          state.checkListQuestions = state.checkListQuestions.map((section) => {
            if (section.sectionId === sectionId) {
              return {
                ...section,
                questions: section.questions.map((q) => {
                  if (q.questionId === questionId) {
                    return {
                      ...q,
                      documentLink: action.payload.fileUrl,
                      documentName: action.payload.fileName,
                    };
                  }
                  return q;
                }),
              };
            }
            return section;
          });
          state.uploadDocData = null;
          state.uploadDocError = null;
        } else {
          state.uploadDocError = res.message;
        }
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        state.docUploadLoading = false;
        state.showDocUpload = false;
        state.uploadDocError = action.error.message;
      });

    // ADD NEW CHECKLIST
    builder
      .addCase(addNewCheckList.pending, (state) => {
        state.addCheckListLoading = true;
      })
      .addCase(addNewCheckList.fulfilled, (state, action) => {
        state.addCheckListLoading = false;
        const res = action.payload;
        if (res.success === true) {
          toast.success("Checklist Created Successfully!");
        } else {
          toast.error(res.message);
        }
      })
      .addCase(addNewCheckList.rejected, (state, action) => {
        state.addCheckListLoading = false;
      });

    // FETCH ALL CHECKLISTS
    builder
      .addCase(fetchAllCheckLists.pending, (state) => {
        state.fetchCheckListLoading = true;
      })
      .addCase(fetchAllCheckLists.fulfilled, (state, action) => {
        state.fetchCheckListLoading = false;
        const res = action.payload;
        if (res.success === true) {
          state.checkList = res.allCheckList;
          state.paginationData = {
            currentPage: res.currentPage,
            totalPages: res.totalPages,
            totalCount: res.count,
          };
        } else {
          toast.error(res.message);
        }
      })
      .addCase(fetchAllCheckLists.rejected, (state, action) => {
        console.log("FETCH ALL CHECKLISTS ERROR", action.error.message);
        state.fetchCheckListLoading = false;
      });

    // FETCH CHECKLIST BY ID
    builder
      .addCase(fetchCheckListById.pending, (state) => {
        state.fetchCheckListLoading = true;
      })
      .addCase(fetchCheckListById.fulfilled, (state, action) => {
        state.fetchCheckListLoading = false;
        const res = action.payload;
        if (res.success === true) {
          state.previewCheckList = res.checkList;
        } else {
          toast.error(res.message);
        }
      })
      .addCase(fetchCheckListById.rejected, (state, action) => {
        console.log("FETCH CHECKLIST BY ID ERROR", action.error.message);
        state.fetchCheckListLoading = false;
      });

    // GET EDIT CHECKLIST DATA
    builder

      .addCase(getEditCheckListData.pending, (state) => {
        state.fetchCheckListLoading = true;
      })
      .addCase(getEditCheckListData.fulfilled, (state, action) => {
        state.fetchCheckListLoading = false;
        const res = action.payload;
        if (res.success === true) {
          const updatedCheckList = res.checkList.checklist.map((section) => {
            return {
              ...section,
              heading: getSectionTitleFromId(section.sectionId),
              questions: section.questions.map((question) => {
                return {
                  ...question,
                  label: getQuestionLabelFromId(
                    section.sectionId,
                    question.questionId
                  ),
                  inputName: getQuestionInputNameFromId(
                    section.sectionId,
                    question.questionId
                  ),
                  index: getQuestionIndexFromId(
                    section.sectionId,
                    question.questionId
                  ),

                  width: getQuestionWidthFromId(
                    section.sectionId,
                    question.questionId
                  ),
                  subFields: question.subFields.map((subField) => {
                    return {
                      ...subField,
                      label: getSubQuestionLabelFromId(
                        section.sectionId,
                        question.questionId,
                        subField.subFieldId
                      ),
                      inputName: getSubQuestionInputNameFromId(
                        section.sectionId,
                        question.questionId,
                        subField.subFieldId
                      ),
                      width: getSubQuestionWidthFromId(
                        section.sectionId,
                        question.questionId,
                        subField.subFieldId
                      ),
                    };
                  }),
                };
              }),
            };
          });
          state.checkListQuestions = updatedCheckList;
        }
      })
      .addCase(getEditCheckListData.rejected, (state, action) => {
        state.fetchCheckListLoading = false;
      });

    // EDIT CHECKLIST
    builder
      .addCase(editCheckList.pending, (state) => {
        state.addCheckListLoading = true;
      })
      .addCase(editCheckList.fulfilled, (state, action) => {
        state.addCheckListLoading = false;
        const res = action.payload;
        if (res.success === true) {
          console.log("EDIT CHECKLIST RESPONSE:", res);
          toast.success("Checklist Updated Successfully!");
        } else {
          toast.error(res.message);
        }
      })
      .addCase(editCheckList.rejected, (state, action) => {
        state.addCheckListLoading = false;
      });

    // DELETE CHECKLIST
    builder
      .addCase(deleteCheckList.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteCheckList.fulfilled, (state, action) => {
        state.deleteLoading = false;
        const res = action.payload;
        if (res.success === true) {
          toast.success("Checklist Deleted Successfully!");
          state.checkList = state.checkList.filter(
            (checkList) => checkList._id !== state.deleteCheckListId
          );
        } else {
          toast.error(res.message);
        }
      })
      .addCase(deleteCheckList.rejected, (state, action) => {
        state.deleteLoading = false;
      });
  },
});

export default createCheckListSlice.reducer;
export const {
  updateInputValue,
  setShowDocUpload,
  removeUploadDocumentLink,
  validateCheckListForm,
  resetCheckListForm,
  setDeleteDialogueOpen,
  setDeleteCheckListId,
  setSearchQuery,
} = createCheckListSlice.actions;
