import React from "react";
import { Loader } from "lucide-react";

export default function CButton({
  children,
  onClick,
  variant = "primary",
  size = "md",
  isLoading = false,
  disabled = false,
  icon: Icon,
  classes = "",
}) {
  const baseStyles =
    "flex items-center justify-center rounded-lg font-medium transition-all duration-200";

  const variants = {
    primary:
      "bg-primaryClr text-white text-xl font-semibold hover:bg-opacity-80 shadow-2xl",
    secondary: "bg-gray-100 text-black hover:bg-gray-200",
    outline: "border border-primaryClr text-gray-700 hover:bg-gray-100",
    danger: "bg-red-600 text-white hover:bg-red-700",
  };

  const sizes = {
    sm: "px-3 py-1 text-sm",
    md: "px-8 py-1 text-base",
    lg: "px-6 py-3 text-lg",
  };

  return (
    <button
      onClick={onClick}
      className={`${classes} ${baseStyles} ${variants[variant]} ${
        sizes[size]
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      disabled={disabled || isLoading}
    >
      {isLoading ? (
        <Loader className="w-5 h-5 animate-spin mr-2" />
      ) : Icon ? (
        <Icon className="w-5 h-5 mr-2 " />
      ) : null}
      {children}
    </button>
  );
}
