import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../components/CheckList/Form.css";
import CTextField from "../components/common/CTextField";
import {
  updateInputValue,
  setShowDocUpload,
  validateCheckListForm,
  resetCheckListForm,
} from "../hooks/createCheckListSlice";
import { Upload, Download } from "lucide-react";
import UploadDocumentDialogue from "../components/common/UploadDocumentDialogue";
import CButton from "../components/common/CButton";
import { toast } from "react-toastify";
import {
  addNewCheckList,
  editCheckList,
} from "../hooks/createCheckListReducers";
import { useNavigate, useParams } from "react-router-dom";
import CLoader from "../components/common/CLoader";
import { getEditCheckListData } from "../hooks/createCheckListReducers";

const AddNewCheckList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { addCheckListLoading, checkListQuestions, showDocUpload } =
    useSelector((state) => state.createCheckListState);

  //SET EDIT CHECKLIST DATA
  useEffect(() => {
    if (id) {
      dispatch(getEditCheckListData(id));
    } else {
      dispatch(resetCheckListForm());
    }
  }, [id]);

  const { user } = useSelector((state) => state.authState);
  //HANDLE INPUT CHANGE
  const handleInputChange = ({
    sectionId,
    questionId,
    value,
    isRemark,
    subFieldId,
  }) => {
    dispatch(
      updateInputValue({
        sectionId,
        questionId,
        value,
        isRemark,
        subFieldId,
      })
    );
  };

  //HANDLE SUBMIT
  const handleSubmit = async () => {
    dispatch(validateCheckListForm());
    const isValid = checkListQuestions.every((section) =>
      section.questions.every((question) => {
        if (question.formError) {
          return false;
        } else {
          return true;
        }
      })
    );
    if (!isValid) {
      toast.error("Please fill all required fields.");
      return;
    }
    // Prepare the payload for submission
    const data = checkListQuestions.map((section) => {
      return {
        sectionId: section.sectionId,
        questions: section.questions.map((question) => {
          return {
            questionId: question.questionId,
            value: question.value,
            remarkValue: question.remarkValue,
            visible: question.visible,
            showSubFields: question.showSubFields,
            showRemarks: question.showRemarks,
            showDocumentUpload: question.showDocumentUpload,
            documentLink: question.documentLink,
            documentName: question.documentName,
            type: question.type,
            subFields: question.subFields.map((subField) => {
              return {
                subFieldId: subField.subFieldId,
                value: subField.value,
                visible: subField.visible,
                type: subField.type,
              };
            }),
          };
        }),
      };
    });

    if (!user._id) {
      toast.error("User ID is missing.Please login again.");
      return;
    }
    const payload = {
      companyName: checkListQuestions[0].questions[2].value,
      createdAt: new Date().toISOString(),
      createdBy: user._id,
      updatedAt: null,
      updatedBy: null,
      isDeleted: false,
      checklist: data,
    };
    let result = null;
    if (id) {
      result = await dispatch(
        editCheckList({
          id,
          payload: {
            ...payload,
            updatedAt: new Date().toISOString(),
            updatedBy: user._id,
          },
        })
      );
    } else {
      result = await dispatch(
        addNewCheckList({
          ...payload,
        })
      );
    }
    if (result.payload.success) {
      navigate(-1);
    }
  };

  return (
    <div className="px-12 p-4 w-full">
      {showDocUpload && <UploadDocumentDialogue />}
      {addCheckListLoading && <CLoader />}
      <div className="px-12 p-4">
        {checkListQuestions.map((section) => (
          <div key={section.sectionId} className="mb-8 shadow-md p-4">
            <h2 className="text-xl font-semibold mb-4 border-b-2 pb-2">
              {`${section.sectionId}. ${section.heading}`}
            </h2>
            <div className="flex flex-wrap items-center w-full">
              {section.questions
                .filter((q) => q.visible)
                .map((q) => (
                  <div
                    key={q.questionId}
                    className={`${q.width} mb-2 pr-10 flex flex-row items-end justify-end`}
                  >
                    <CTextField
                      key={q.questionId}
                      label={q.label}
                      qIndex={q.index}
                      name={q.inputName}
                      error={q.formError}
                      type={q.type}
                      rows={q.rows}
                      value={q.value}
                      onChange={(e) => {
                        handleInputChange({
                          sectionId: section.sectionId,
                          questionId: q.questionId,
                          isRemark: false,
                          value: e.target.value,
                        });
                      }}
                    />

                    {/* -------------- SUB FIELD ---------------- */}
                    {q.showSubFields &&
                      q.subFields &&
                      q.subFields.length > 0 && (
                        <div className="pl-4 flex gap-4">
                          {q.subFields
                            .filter((subQ) => subQ.visible)
                            .map((subQ) => (
                              <CTextField
                                key={subQ.subFieldId}
                                label={subQ.label}
                                qIndex={subQ.index}
                                name={subQ.inputName}
                                type={subQ.type}
                                value={subQ.value}
                                onChange={(e) => {
                                  handleInputChange({
                                    sectionId: section.sectionId,
                                    questionId: q.questionId,
                                    subFieldId: subQ.subFieldId,
                                    isRemark: false,
                                    value: e.target.value,
                                  });
                                }}
                              />
                            ))}
                        </div>
                      )}
                    {/* -------------- REMARK --------------*/}
                    {q.showRemarks && (
                      <CTextField
                        className="ml-4"
                        label="Remarks"
                        name={`${q.inputName}Remarks`}
                        type="text"
                        value={q.remarkValue || ""}
                        onChange={(e) => {
                          handleInputChange({
                            sectionId: section.sectionId,
                            questionId: q.questionId,
                            isRemark: true,
                            value: e.target.value,
                          });
                        }}
                        width="w-full"
                      />
                    )}
                    {/* ----------------- DOCUMENT UPLOAD ---------------- */}
                    {q.showDocumentUpload && (
                      <div
                        className="ml-2 mb-7 relative flex items-center gap-2 bg-gray-100 p-2 rounded-md cursor-pointer"
                        onClick={() =>
                          dispatch(
                            setShowDocUpload({
                              showDialogue: true,
                              uploadDocData: {
                                sectionId: section.sectionId,
                                questionId: q.questionId,
                                documentLink: q.documentLink,
                                documentName: q.documentName,
                              },
                            })
                          )
                        }
                      >
                        {q.documentLink ? (
                          <Download className="w-5 h-5 text-blue-600" />
                        ) : (
                          <Upload className="w-5 h-5 text-orange-600" />
                        )}

                        <input
                          id={`fileInput-${q.id}`}
                          type="file"
                          className="hidden"
                          onChange={(e) => {
                            e.preventDefault();
                            if (e.target.files?.length) {
                              console.log("FILE", e.target.files[0]);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ))}
        {/* ------------SUBMIT BUTTON--------------- */}
        <div className="w-full flex flex-row justify-end px-4 gap-6">
          <CButton
            className="mr-4"
            variant="secondary"
            onClick={() => {
              navigate(-1);
            }}
            children={"Cancel"}
          />
          <CButton
            isLoading={addCheckListLoading}
            onClick={handleSubmit}
            children={"Submit"}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewCheckList;

/*



*/
