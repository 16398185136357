import "./App.css";
import ViewCheckList from "./components/CheckList/ViewCheckList";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Login from "./components/user/Login";
import CheckList from "./components/CheckList/CheckLists";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateCheckList from "./components/CheckList/CreateCheckList";
import CheckListSearch from "./components/CheckList/ChecklistSearch";
import Register from "./components/user/Register";
import { useEffect } from "react";
import store from "./store";
import { authenticateUser, loadUser } from "./actions/userAction";
import ProtectedRoute from "./components/route/ProtectedRoute";
import UpdateCheckList from "./components/CheckList/UpdateCheckList";
import HomeLayout from "./components/layouts/HomeLayout";
import AddNewCheckList from "./pages/AddNewCheckList";
import PreviewCheckList from "./pages/PreviewCheckList";
import DashBoard from "./pages/DashBoard";

function App() {
  // useEffect(() => {
  //   store.dispatch(loadUser);
  // });

  return (
    <Router>
      <div className="App">
        <HomeLayout>
          <ToastContainer />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <DashBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/find/:keyword"
              element={
                <ProtectedRoute>
                  <CheckListSearch />
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<Login />} />
            <Route
              path="/view/:id"
              element={
                <ProtectedRoute>
                  <ViewCheckList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/new"
              element={
                <ProtectedRoute>
                  <CreateCheckList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-new"
              element={
                <ProtectedRoute>
                  <AddNewCheckList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/edit/:id"
              element={
                <ProtectedRoute>
                  <AddNewCheckList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/preview/:id"
              element={
                <ProtectedRoute>
                  <PreviewCheckList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/update/:id"
              element={
                <ProtectedRoute>
                  <UpdateCheckList />
                </ProtectedRoute>
              }
            />
            <Route path="/admin/register" element={<Register />} />
          </Routes>
        </HomeLayout>
      </div>
    </Router>
  );
}

export default App;
