export class ApiEndPoints {
  // static BASE_URL = "http://localhost:8080/";
  static BASE_URL = "https://api.audit.bizpole.in/";
  static UPLOAD_DOCUMENT = "api/v1/checklist/upload";
  static ADD_NEW_CHECKLIST = "api/v1/checklist/create";
  static EDIT_CHECKLIST = "api/v1/new/checklist";
  static DELETE_CHECKLIST = "api/v1/new/checklist";
  static FETCH_ALL_CHECKLISTS = "api/v1/new/checklist/view-all";
  static FETCH_CHECKLIST_BY_ID = "api/v1/new/checklist";
}
