import axios from "axios";

export const apiType = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const apiRequest = async ({
  url,
  apiType,
  body,
  isFormData = false,
  withCredentials = true, // Added option to enable credentials
}) => {
  try {
    const headers = isFormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" };

    const data = isFormData ? new FormData() : body;
    if (isFormData && body) {
      Object.keys(body).forEach((key) => {
        data.append(key, body[key]);
      });
    }

    const response = await axios({
      method: apiType,
      url,
      data,
      headers,
      withCredentials, // Ensures credentials (cookies, authentication headers) are included
    });

    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
