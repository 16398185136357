import { questions } from "../constants/questions";

export const checkListValidation = (state, action) => {
  const { sectionId, questionId, value } = action.payload;
  state.checkListQuestions = state.checkListQuestions.map((section) => {
    if (section.sectionId !== sectionId) return section;
    return {
      ...section,
      questions: section.questions.map((q) => {
        switch (`${sectionId}-${questionId}`) {
          // SECTION - 1 ------------------------------------------------
          case "1-8":
            return q.questionId === 10 ? { ...q, visible: value !== "yes" } : q;

          case "1-11":
            return q.questionId === 11
              ? { ...q, showSubFields: value === "yes" }
              : q;

          case "1-19":
            return q.questionId === 19
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "1-24":
            return q.questionId === 24
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "1-25":
            return q.questionId === 25
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "1-26":
            return q.questionId === 26
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "1-27":
            return q.questionId === 27
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "1-28":
            return q.questionId === 28
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "3-41":
            return q.questionId === 41
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "3-42":
            return q.questionId === 42
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "3-43":
            return q.questionId === 43
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "3-44":
            return q.questionId === 44
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "4-48":
            return q.questionId === 48
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "4-50":
            return q.questionId === 50
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "4-51":
            return q.questionId === 51
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "5-57":
            return q.questionId === 57
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "5-59":
            return q.questionId === 59
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "5-60":
            return q.questionId === 60
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "8-73":
            return q.questionId === 73
              ? { ...q, showSubFields: value === "yes" }
              : q;
          case "9-78":
            return q.questionId === 78
              ? { ...q, showSubFields: value === "yes" }
              : q;

          case "20-112":
            return q.questionId === 113
              ? { ...q, visible: value === "yes" }
              : q;
          case "21-115":
            return q.questionId === 116
              ? { ...q, visible: value === "yes" }
              : q;
          case "22-118":
            return q.questionId === 119
              ? { ...q, visible: value === "yes" }
              : q;

          default:
            return q;
        }
      }),
    };
  });
};

// GETS SECTION TITLE FROM SECTION ID
export const getSectionTitleFromId = (sectionId) => {
  let sectionTitle = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      sectionTitle = section.heading;
    }
  });
  return sectionTitle;
};

//GET QUESTION LABEL FROM QUESTION ID
export const getQuestionLabelFromId = (sectionId, questionId) => {
  let questionLabel = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          questionLabel = question.label;
        }
      });
    }
  });
  return questionLabel;
};

// GET QUESTION INDEX FROM QUESTION ID
export const getQuestionIndexFromId = (sectionId, questionId) => {
  let questionIndex = 0;
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          questionIndex = question.index;
        }
      });
    }
  });
  return questionIndex;
};

//GET QUESTION WIDTH FROM QUESTION ID
export const getQuestionWidthFromId = (sectionId, questionId) => {
  let questionWidth = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          questionWidth = question.width;
        }
      });
    }
  });
  return questionWidth;
};

//GET QUESTION INPUT NAME FROM QUESTION ID
export const getQuestionInputNameFromId = (sectionId, questionId) => {
  let questionInputName = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          questionInputName = question.inputName;
        }
      });
    }
  });
  return questionInputName;
};

//GET SUB QUESTION LABEL FROM QUESTION ID
export const getSubQuestionLabelFromId = (
  sectionId,
  questionId,
  subFieldId
) => {
  let questionLabel = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          question.subFields.map((subField) => {
            if (subField.subFieldId === subFieldId) {
              questionLabel = subField.label;
            }
          });
        }
      });
    }
  });
  return questionLabel;
};
// GET SUB QUESTION WIDTH FROM QUESTION ID
export const getSubQuestionWidthFromId = (
  sectionId,
  questionId,
  subFieldId
) => {
  let questionWidth = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          question.subFields.map((subField) => {
            if (subField.subFieldId === subFieldId) {
              questionWidth = subField.width;
            }
          });
        }
      });
    }
  });
  return questionWidth;
};
//GET SUB QUESTION INPUT NAME FROM QUESTION ID
export const getSubQuestionInputNameFromId = (
  sectionId,
  questionId,
  subFieldId
) => {
  let questionInputName = "";
  questions.map((section) => {
    if (section.sectionId === sectionId) {
      section.questions.map((question) => {
        if (question.questionId === questionId) {
          question.subFields.map((subField) => {
            if (subField.subFieldId === subFieldId) {
              questionInputName = subField.inputName;
            }
          });
        }
      });
    }
  });
  return questionInputName;
};
