export const questions = [
  {
    sectionId: 1,
    heading: "Basics Details",
    questions: [
      {
        questionId: 1,
        index: 1,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "date",
        label: "Order Date",
        inputName: "orderDate",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-1/2",
      },
      {
        questionId: 2,
        index: 2,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "date",
        label: "Target Date",
        inputName: "targetDate",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-1/2",
      },
      {
        questionId: 3,
        index: 3,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "BUSINESS NAME",
        inputName: "businessName",
        formError: "",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-full",
      },
      {
        questionId: 4,
        index: 4,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "BUSINESS FORMAT",
        inputName: "businessFormat",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-full",
      },
      {
        questionId: 5,
        index: 5,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "PLACE OF BUSINESS",
        inputName: "placeOfBusiness",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-1/2",
      },
      {
        questionId: 6,
        index: 6,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "STATE",
        inputName: "state",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-1/2",
      },
      {
        questionId: 7,
        index: 7,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "BUSINESS ACTIVITY",
        inputName: "businessActivity",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        subFields: [],
        width: "w-full",
      },
      {
        questionId: 8,
        index: 8,
        visible: true,
        showRemarks: true,
        type: "checkbox",
        label: "IS THIS YEAR'S AUDITOR THE SAME AS LAST YEAR'S?",
        inputName: "isThisYearsAuditorTheSame",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 9,
        index: 9,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHO IS THE CURRENT YEAR AUDITOR?",
        inputName: "currentYearAuditor",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-1/2",
        subFields: [],
      },
      {
        questionId: 10,
        index: 9.1,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "IF NO, THEN WHO IS THE PREVIOUS AUDITOR?",
        inputName: "ifNoPreviousAuditor",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-1/2",
        subFields: [],
      },
      {
        questionId: 11,
        index: 10,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE FORM ADT 3 HAS BEEN FILED BY THE PRE AUDITOR?",
        inputName: "filedByPreAuditor",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        showSubFields: false,
        subFields: [
          {
            subFieldId: 1,
            index: 10.1,
            visible: true,
            type: "text",
            label: "IF YES, PLEASE MENTION SRN NUMBER",
            inputName: "filedByPreAuditorSRN",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      {
        questionId: 12,
        index: 11,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE FORM ADT 3 HAS BEEN VERIFIED?",
        inputName: "formADT3Verified",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 13,
        index: 12,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE NOC FROM PRE AUDITOR HAS BEEN RECEIVED?",
        inputName: "nocReceivedFromPreAuditor",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 14,
        index: 13,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "WHETHER THE FORM ADT 1 FILED BY THE COMPANY FOR APPOINTMENT OF NEW AUDITOR?",
        inputName: "formADT1Filed",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 13.1,
            visible: true,
            type: "text",
            label: "IF YES, PLEASE MENTION SRN NUMBER",
            inputName: "formADT1FiledSRN",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      {
        questionId: 15,
        index: 14,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE FORM ADT 1 HAS BEEN VERIFIED?",
        inputName: "formADT1Verified",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 16,
        index: 15,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: " WHETHER THE FORM AOC 4 FOR THE PRE YEAR HAS BEEN FILED?",
        inputName: "formAOC4Filed",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 17,
        index: 16,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE FORM MGT 7A FOR THE PRE YEAR HAS BEEN FILED?",
        inputName: "formMGT7AFiled",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 18,
        index: 17,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER PY AUDITED FINANCIAL & GST AR VERIFIED?",
        inputName: "pyAuditedFinancialGSTVerified",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 19,
        index: 18,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER THE FORM INC 20A IS FILED?",
        inputName: "formINC20AFiled",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 18.1,
            visible: true,
            type: "text",
            label: "IF YES, PLEASE MENTION SRN NUMBER",
            inputName: "formINC20AFiledSRN",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      {
        questionId: 20,
        index: 19,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "HOW MANY BANK ACCOUNTS DOES THE COMPANY HAVE?",
        inputName: "numberOfBankAccounts",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 21,
        index: 20,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HOW MANY BANK ACCOUNTS RECEIVED?",
        inputName: "numberOfBankAccountsReceived",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 22,
        index: 21,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF OPENED LATER, FROM THE OPENING DATE TO THE YEAR END?",
        inputName: "statementsCoverWholeYear",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 23,
        index: 22,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "DOES THE OPENING BALANCES ARE MATCHING WITH THE PY CLOSING FIGURES?",
        inputName: "openingBalancesMatchingPY",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 24,
        index: 23,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE REGISTERED UNDER GST?",
        inputName: "hasRegisteredUnderGST",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        showSubFields: false,
        subFields: [
          {
            subFieldId: 1,
            index: 23.1,
            visible: true,
            type: "text",
            label: "Username",
            inputName: "gstLoginCredentialsUsername",
            value: "",
            width: "w-1/2",
          },
          {
            subFieldId: 2,
            index: 23.2,
            visible: true,
            type: "text",
            label: "Password",
            inputName: "gstLoginCredentialsPassword",
            value: "",
            width: "w-1/2",
          },
        ],
      },

      // INCOME TAX
      {
        questionId: 25,
        index: 24,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE REGISTERED UNDER INCOME TAX?",
        inputName: "hasRegisteredUnderIncomeTax",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 24.1,
            visible: true,
            type: "text",
            label: "Username",
            inputName: "incomeTaxLoginCredentialsUsername",
            value: "",
            width: "w-1/2",
          },
          {
            subFieldId: 2,
            index: 24.2,
            visible: true,
            type: "text",
            label: "Password",
            inputName: "incomeTaxLoginCredentialsPassword",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      // ESI
      {
        questionId: 26,
        index: 25,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE REGISTERED UNDER ESI?",
        inputName: "hasRegisteredUnderESI",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 25.1,
            visible: true,
            type: "text",
            label: "Username",
            inputName: "esiLoginCredentialsUsername",
            value: "",
            width: "w-1/2",
          },
          {
            subFieldId: 2,
            index: 25.2,
            visible: true,
            type: "text",
            label: "Password",
            inputName: "esiLoginCredentialsPassword",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      // PF
      {
        questionId: 27,
        index: 26,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE REGISTERED UNDER PF?",
        inputName: "hasRegisteredUnderPF",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 26.1,
            visible: true,
            type: "text",
            label: "Username",
            inputName: "pfLoginCredentialsUsername",
            value: "",
            width: "w-1/2",
          },
          {
            subFieldId: 2,
            index: 26.2,
            visible: true,
            type: "text",
            label: "Password",
            inputName: "pfLoginCredentialsPassword",
            value: "",
            width: "w-1/2",
          },
        ],
      },
      //UNDER TRACES
      {
        questionId: 28,
        index: 27,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE REGISTERED UNDER TRACES?",
        inputName: "hasRegisteredUnderTraces",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 27.1,
            visible: true,
            type: "text",
            label: "Username",
            inputName: "tracesLoginCredentialsUsername",
            value: "",
            width: "w-1/2",
          },
          {
            subFieldId: 2,
            index: 27.2,
            visible: true,
            type: "text",
            label: "Password",
            inputName: "tracesLoginCredentialsPassword",
            value: "",
            width: "w-1/2",
          },
        ],
      },
    ],
  },
  // SECTION - 2 : GOODS AND SERVICES TAX
  {
    sectionId: 2,
    heading: "Goods and Services Tax",
    questions: [
      {
        questionId: 29,
        index: 28,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: " Goods and Services Dealing with & Applicable GST Rates",
        inputName: "goodsAndServicesDealing",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 30,
        index: 29,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "Sales Turnover Reconciliation",
        inputName: "salesTurnoverReconciliation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 31,
        index: 30,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "Purchase Turnover Reconciliation",
        inputName: "purchaseTurnoverReconciliation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 32,
        index: 31,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "GST Input & Output Reconciliation",
        inputName: "gstInputOutputReconciliation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 33,
        index: 32,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "Any Export/Import Proceeds?",
        inputName: "anyExportImportProceeds",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 34,
        index: 33,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "Have any Exports Turnover?",
        inputName: "haveAnyExportsTurnover",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 35,
        index: 34,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "Have Applied for LUT in GST Portal?",
        inputName: "anyGSTDemandPending",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 36,
        index: 35,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "Copy of LUT to be obtained for the year under review",
        inputName: "copyOfLUTToBeObtained",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 37,
        index: 36,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: " Delayed Payments to Creditors/Vendors over 180 Days",
        inputName: "delayedPaymentsToCreditors",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 38,
        index: 37,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "GST Input on Such Delayed Transactions",
        inputName: "gstInputOnDelayedTransactions",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },
  // SECTION - 3 : INCOME TAX
  {
    sectionId: 3,
    heading: "INCOME TAX",
    questions: [
      {
        questionId: 39,
        index: 38,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY TDS DEDUCTIONS DURING THE YEAR?",
        inputName: "anyTDSDeductionsDuringYear",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 40,
        index: 39,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "PREPARED TDS&TCS RECONCILIATION?",
        inputName: "preparedTDSTCSReconciliation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 41,
        index: 40,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY TDS&TCS LOWER DEDUCTION OBSERVED?",
        inputName: "anyTDSTCSLowerDeductionObserved",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 40.1,
            visible: true,
            type: "text",
            label: "IF YES, GIVE DETAILS OF TDS&TCS LOWER DEDUCTION",
            inputName: "tdsTCSDetailsLowerDeduction",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 42,
        index: 41,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY TDS&TCS NON DEDUCTION OBSERVED?",
        inputName: "anyTDSTCSNonDeductionObserved",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 41.1,
            visible: true,
            type: "text",
            label: " IF YES, GIVE DETAILS OF TDS&TCS NON DEDUCTION OBSERVED",
            inputName: "tdsTCSNonDeductionDetails",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 43,
        index: 42,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY TDS&TCS NON PAYMENTS OBSERVED?",
        inputName: "anyTDSTCSNonPaymentsObserved",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 42.1,
            visible: true,
            type: "text",
            label: " IF YES, GIVE DETAILS OF TDS&TCS NON PAYMENTS OBSERVED",
            inputName: "tdsTCSNonPaymentsDetails",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 44,
        index: 43,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY DISALLOWANCE BASED ON THE ABOVE?",
        inputName: "anyDisallowanceBasedOnAbove",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 43.1,
            visible: true,
            type: "text",
            label: "IF YES, GIVE DETAILS OF DISALLOWANCE",
            inputName: "disallowanceDetails",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 45,
        index: 44,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHECK INCOME AS PER 26 AS & ACCOUNTS?",
        inputName: "checkIncomeAsPer26ASAccounts",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 46,
        index: 45,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "TDS&TCS CREDIT AS PER 26 AS & ACCOUNTS ARE MATCHING?",
        inputName: "tdsTCSCreditMatching",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 47,
        index: 46,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY ADDITIONAL REPORTING IN AIS NOT REFLECTED IN ACCOUNTS?",
        inputName: "additionalReportingAISNotReflectedInAccounts",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },
  // SECTION - 4 : FIXED ASSETS
  {
    sectionId: 4,
    heading: "FIXED ASSETS",
    questions: [
      {
        questionId: 48,
        index: 47,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY PURCHASES DURING THE YEAR?",
        inputName: "anyPurchasesDuringYear",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 47.1,
            visible: true,
            type: "checkbox",
            label: "IF YES, INVOICES FOR FIXED ASSETS ARE VERIFIED?",
            inputName: "invoicesForFixedAssetsVerified",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 49,
        index: 48,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHECK GST INPUT CREDIT UTILIZATION",
        inputName: "checkGSTInputCreditUtilization",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 50,
        index: 49,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY SALE OF FIXED ASSETS DURING THE YEAR?",
        inputName: "anySaleOfFixedAssetsDuringYear",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 49.1,
            visible: true,
            type: "checkbox",
            label: "IF YES, INVOICES FOR FIXED ASSETS ARE VERIFIED?",
            inputName: "invoicesForSaleOfFixedAssetsVerified",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 51,
        index: 50,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY COMPANY VEHICLES USED FOR PERSONAL PURPOSES?",
        inputName: "companyVehiclesUsedForPersonalPurposes",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 50.1,
            visible: true,
            type: "checkbox",
            label: "WHAT IS THE DISALLOWANCE ON THAT?",
            inputName: "disallowanceOnCompanyVehicles",
            value: "",
            width: "w-full",
          },
        ],
      },
    ],
  },
  // SECTION - 5 : BANK LOANS
  {
    sectionId: 5,
    heading: "BANK LOANS",
    questions: [
      {
        questionId: 52,
        index: 51,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "HOW MANY BANK LOANS DOES THE COMPANY HAVE?",
        inputName: "numberOfBankLoanAccounts",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 53,
        index: 52,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "HOW MANY BANK ACCOUNTS RECEIVED?",
        inputName: "bankAccountsLoanReceived",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 54,
        index: 53,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "DO THE STATEMENTS COVER EITHER THE WHOLE YEAR OR, IF LOAN AVAILED LATER, FROM THE OPENING DATE TO THE YEAR END?",
        inputName: "statementsCoverage",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 55,
        index: 54,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY NEW LOANS SANCTIONED DURING THE PY?",
        inputName: "newLoansSanctionedDuringPY",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 56,
        index: 55,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "IS IT REQUIRED TO CREATE CHARGE WITH ROC?",
        inputName: "chargeCreationRequiredWithROC",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 57,
        index: 56,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHARGE FORMS RECEIVED FOR VERIFICATION?",
        inputName: "chargeFormsReceivedForVerification",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 56.1,
            visible: true,
            type: "text",
            label: "IF YES ENTER THE SRN",
            inputName: "chargeFormsSRN",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 58,
        index: 57,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHECK BANK LOAN UTILIZATION",
        inputName: "bankLoanUtilizationChecked",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 59,
        index: 58,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "ANY UTILIZATION FOR OTHER THAN THE PURPOSE FOR WHICH THE LOAN SANCTIONED?",
        inputName: "otherPurposeUtilizationDetails",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 58.1,
            visible: true,
            type: "text",
            label: "IF YES, GIVE DETAILS",
            inputName: "otherPurposeUtilizationDetails",
            value: "",
            width: "w-full",
          },
        ],
      },

      {
        questionId: 60,
        index: 59,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "ANY OD/CC LIMITS SANCTIONED UTILIZED FOR PERSONAL / LONG TERM PURPOSES?",
        inputName: "odCcLimitsUtilizedForPersonalOrLongTerm",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 59.1,
            visible: true,
            type: "text",
            label: "IF YES, GIVE DETAILS",
            inputName: "odCcLimitsUtilizedDetails",
            value: "",
            width: "w-full",
          },
        ],
      },
    ],
  },

  // SECTION - 6 : SUNDRY DEBTORS
  {
    sectionId: 6,
    heading: "SUNDRY DEBTORS",
    questions: [
      {
        questionId: 61,
        index: 60,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "LIST OF SUNDRY DEBTORS AS ON THE YEAR END",
        inputName: "sundryDebtorsList",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 62,
        index: 61,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS?",
        inputName: "outstandingAmountOverSixMonths",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 63,
        index: 62,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR?",
        inputName: "amountOutstandingOverOneYear",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 64,
        index: 63,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT DEBTORS?",
        inputName: "verifiedAccountStatementWithDebtors",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 7 : SUNDRY CREDITORS
  {
    sectionId: 7,
    heading: "SUNDRY CREDITORS",
    questions: [
      {
        questionId: 65,
        index: 64,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "LIST OF SUNDRY CREDITORS",
        inputName: "sundryCreditorsList",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 66,
        index: 65,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHAT IS THE OUTSTANDING AMOUNT OVER 6 MONTHS?",
        inputName: "outstandingAmountOverSixMonthsCreditor",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 67,
        index: 66,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHAT IS THE AMOUNT OUTSTANDING OVER 1 YEAR?",
        inputName: "amountOutstandingOverOneYearCreditors",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 68,
        index: 67,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHAT IS THE NUMBER OF SME VENDORS / SUPPLIERS?",
        inputName: "numberOfSMEVendors",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 69,
        index: 68,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "NO OF ACCOUNTS O/S OVER 15/45 DAYS",
        inputName: "numberOfAccountsOutstandingOver15or45Days",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 70,
        index: 69,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "AMOUNT O/S OVER 15/45 DAYS",
        inputName: "amountOutstandingOver15or45Days",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 71,
        index: 70,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "DISALLOWANCE AMOUNT U/S 44 B(H)",
        inputName: "disallowanceAmountUnder44BH",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 72,
        index: 71,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "VERIFIED THE COPY OF ACCOUNT STATEMENT WITH SIGNIFICANT CREDITORS?",
        inputName: "verifiedAccountStatementWithCreditors",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 8 : INVESTMENTS
  {
    sectionId: 8,
    heading: "INVESTMENTS",
    questions: [
      {
        questionId: 73,
        index: 72,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE ANY INVESTMENTS IN THE NAME OF THE ENTITY?",
        inputName: "investmentsInEntity",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 72.1,
            visible: true,
            type: "text",
            label: "VERIFIED THE DETAILS",
            inputName: "verifiedInvestmentDetails",
            value: "",
            width: "w-full",
          },
          {
            subFieldId: 2,
            index: 72.2,
            visible: true,
            type: "text",
            label: "Enter investment income",
            inputName: "investmentIncome",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 74,
        index: 73,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "IN RESPECT OF INVESTMENTS IN SHARES, VERIFY THE YEAR’S BALANCE SHEETS UNDER AUDIT IN RESPECT OF INVESTED COMPANIES.",
        inputName: "verifyInvestmentBalanceSheets",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 75,
        index: 74,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "IN CASE OF INVESTMENT IN LISTED SHARES – COPY OF ALL CONTRACT NOTES FROM THE BROKERS TO BE OBTAINED",
        inputName: "obtainBrokerContractNotes",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 76,
        index: 75,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "CAPITAL GAIN / (LOSS) COMPUTATION TO BE OBTAINED AND TO BE TALLIED WITH BOOKS OF ACCOUNTS",
        inputName: "obtainCapitalGainLossComputation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 77,
        index: 76,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          " WHETHER INVESTMENT IN SHARES IN ANY RELATED PARTY COMPLIES WITH COMPANIES ACT, 2013",
        inputName: "complianceWithCompaniesAct",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 9 : CARGO
  {
    sectionId: 9,
    heading: "CARGO",
    questions: [
      {
        questionId: 78,
        index: 77,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "IS CARGO APPLICABLE?",
        inputName: "isCARGOApplicable",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 77.1,
            visible: true,
            type: "text",
            label: "Reason for Applicability",
            inputName: "reasonForApplicability",
            value: "",
            width: "w-full",
          },
        ],
      },
    ],
  },

  // SECTION - 10 : FINANCIAL COST
  {
    sectionId: 10,
    heading: "FINANCIAL COST",
    questions: [
      {
        questionId: 79,
        index: 78,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "CHECK THE APPORTIONMENT OF PRINCIPAL & INTEREST AMOUNT AS PER LOAN AMORTIZATION SCHEDULE.",
        inputName: "apportionmentOfPrincipalAndInterest",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 80,
        index: 79,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "WHETHER TDS UNDER SECTION 194A HAS BEEN DEDUCTED ON THE INTEREST PAYMENT TO NBFC OR INDIVIDUALS OR CORPORATE?",
        inputName: "tdsDeductedOnInterestPayment",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 81,
        index: 80,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER PROPER ENTRY HAS BEEN MADE IN THE BOOKS OF ACCOUNTS",
        inputName: "properEntryInBooksOfAccounts",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 11 : DEFERRED TAX ASSETS / LIABILITIES
  {
    sectionId: 11,
    heading: "DEFERRED TAX ASSETS / LIABILITIES",
    questions: [
      {
        questionId: 82,
        index: 81,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHECK THE COMPUTATION",
        inputName: "computationChecked",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 12 : LOANS AND ADVANCES
  {
    sectionId: 12,
    heading: "LOANS AND ADVANCES",
    questions: [
      {
        questionId: 83,
        index: 82,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "WHETHER LOAN HAS BEEN GRANTED TO RELATED PARTY AND RELATIVES",
        inputName: "loanGrantedToRelatedParty",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 84,
        index: 83,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "WHETHER BOD AND COPY OF AGREEMENT APPROPRIATELY AUTHORIZE LOAN AND ADVANCE MADE TO OTHER",
        inputName: "loanGrantedToEmployees",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 85,
        index: 84,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "IS THERE ANY CASH RECEIPTS AND PAYMENTS TO LOAN ACCOUNT ?",
        inputName: "cashReceiptsAndPaymentsToLoanAccount",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 13 : SECURITY
  {
    sectionId: 13,
    heading: "SECURITY",
    questions: [
      {
        questionId: 86,
        index: 85,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "IN CASE THE COMPANY GIVES SECURITY TO THE PARTY, OBTAIN A SECURITY AGREEMENT FROM THE PARTY",
        inputName: "securityAgreement",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 87,
        index: 86,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "VERIFY SECURITY IS REFUNDABLE OR NOT",
        inputName: "securityRefundable",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 88,
        index: 87,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "VERIFY THE AGREEMENT WHETHER THERE IS AN AGREEMENT REGARDING RETURNS ON SECURITY AND SUCH RETURN RECOGNIZED IN THE BOOK OF ACCOUNT",
        inputName: "securityReturnAgreement",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 14 : CASH
  {
    sectionId: 14,
    heading: "CASH",
    questions: [
      {
        questionId: 89,
        index: 88,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "OBTAIN SIGNED CASH BALANCE CERTIFICATE FROM CASHIER AND DIRECTOR AS WELL AS ON MARCH 31ST",
        inputName: "obtainSignedCashBalanceCertificate",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 15 : INVENTORY
  {
    sectionId: 15,
    heading: "INVENTORY",
    questions: [
      {
        questionId: 90,
        index: 89,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "RECEIVED SIGNED CLOSING STOCK CONFIRMATION AS ON MARCH 31ST?",
        inputName: "receivedClosingStockConfirmation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 91,
        index: 90,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "RECEIVED CONFIRMATION OF STOCK LOSS AND ABNORMAL WASTAGE?",
        inputName: "receivedStockLossConfirmation",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 92,
        index: 91,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "REVENUE FROM OPERATIONS",
        inputName: "revenueFromOperations",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 93,
        index: 92,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "text",
        label:
          "COPY OF INVOICES (AT LEAST 5 TO 10) TO BE OBTAINED FOR VARIOUS MONTHS FOR RECORDS",
        inputName: "copyOfInvoices",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 16 : PURCHASES
  {
    sectionId: 16,
    heading: "PURCHASES",
    questions: [
      {
        questionId: 94,
        index: 93,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label:
          "PURCHASES COPY OF INVOICES AT LEAST 5 TO 10 TO BE OBTAINED FOR VARIOUS MONTHS FOR RECORDS",
        inputName: "purchaseAndInvoiceDetails",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 17 : SALARY
  {
    sectionId: 17,
    heading: "SALARY",
    questions: [
      {
        questionId: 95,
        index: 94,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "CHECK APPLICABILITY OF TDS FOR SALARY",
        inputName: "tdsApplicabilitySalary",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 96,
        index: 95,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "ANY SHORT/NON DEDUCTION CASES OBSERVED?",
        inputName: "tdsObservations",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 18 : ESI / EFI
  {
    sectionId: 18,
    heading: "ESI / EFI",
    questions: [
      {
        questionId: 97,
        index: 96,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "number",
        label: "TOTAL NUMBER OF EMPLOYEES",
        inputName: "totalEmployeesForESIEFI",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 98,
        index: 97,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "number",
        label: "NUMBER OF EMPLOYEES COVERED UNDER ESI",
        inputName: "esiCoveredEmployees",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 99,
        index: 98,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "REGISTERED UNDER ESI?",
        inputName: "registeredUnderESI",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 100,
        index: 99,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "ANY ESI SHORT DEDUCTION OBSERVED?",
        inputName: "esiObservationsShort",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 101,
        index: 100,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "ANY ESI NON DEDUCTION OBSERVED?",
        inputName: "registeredUnderEPF",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 102,
        index: 101,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "REGISTERED UNDER EPF?",
        inputName: "registeredUnderEPF",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [
          {
            subFieldId: 1,
            index: 101.1,
            visible: true,
            type: "text",
            label: "ANY ESF SHORT DEDUCTION OBSERVED?",
            inputName: "epfObservationsShort",
            value: "",
            width: "w-full",
          },
          {
            subFieldId: 2,
            index: 101.2,
            visible: true,
            type: "text",
            label: "ANY EPF NON DEDUCTION OBSERVED?",
            inputName: "epfObservationsNon",
            value: "",
            width: "w-full",
          },
        ],
      },
      {
        questionId: 103,
        index: 102,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "ANY DISALLOWANCES RELATED TO PAYMENTS/DEDUCTIONS?",
        inputName: "disallowances",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 19 : OTHER EXPENSES
  {
    sectionId: 19,
    heading: "OTHER EXPENSES",
    questions: [
      {
        questionId: 104,
        index: 103,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label:
          "LIST OUT THE EXPENSES ON WHICH GST REVERSE CHARGE APPLIED & ENTERED ADEQUATELY IN BOOKS OF ACCOUNTS?",
        inputName: "gstReverseChargeExpenses",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 105,
        index: 104,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label:
          "PERSONAL NATURE/CHARITY DONATION/CLUB EXPENSES (FOR TAX AUDIT PURPOSE)",
        inputName: "personalCharityClubExpenses",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 106,
        index: 105,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label:
          "EXAMINE EXPENSE OCCURS REGULARLY., FOR EX. ELECTRICITY EXP. BOOKED EVERY MONTH OR SALARY EXP. BOOKED EVERY MONTH",
        inputName: "regularlyOccurringExpenses",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 107,
        index: 106,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label:
          "EXAMINE WHETHER COMPANY HAD MADE EXPENSE DUE ENTRY AT THE YEAR-END, FOR EX. SALARY OF MARCH MONTH BOOKED",
        inputName: "yearEndExpenseEntries",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 108,
        index: 107,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label:
          "CHECK ANY EXPENDITURE OF CAPITAL NATURE CHARGE IN REVENUE EXPENSES",
        inputName: "capitalNatureExpenses",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },

      {
        questionId: 109,
        index: 108,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "CHECK WHETHER GST TAX INPUT CLAIM ON ANY EXPENSES",
        inputName: "gstInputClaim",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 110,
        index: 109,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "CHECK SUCH INPUT CLAIM IN GST RETURN IN GSTR",
        inputName: "gstReturnGSTR",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 111,
        index: 110,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: " COMPARE THE EXPENSES WITH PY REPORT FLUCTUATIONS",
        inputName: "gstReturnComparison",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },
  // SECTION - 20 : CHANGE IN OWNERSHIP
  {
    sectionId: 20,
    heading: "CHANGE IN OWNERSHIP",
    questions: [
      {
        questionId: 112,
        index: 111,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY CHANGES IN OWNERSHIP DURING THE PREVIOUS YEAR?",
        inputName: "changesOwnershipDuringPY",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 113,
        index: 112,
        visible: false,
        showRemarks: false,
        remarkValue: "",
        type: "textarea",
        rows: 2,
        label: "DETAILS OF SUCH CHANGES",
        inputName: "detailsChangesOwnership",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 114,
        index: 113,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "DOCUMENTS RECEIVED FOR VERIFICATION",
        inputName: "documentsVerificationChangesOwnership",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 21 : CHANGES IN MANAGEMENT
  {
    sectionId: 21,
    heading: "CHANGES IN MANAGEMENT",
    questions: [
      {
        questionId: 115,
        index: 114,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY CHANGES DURING THE PY?",
        inputName: "managementChanges",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 116,
        index: 115,
        visible: false,
        showRemarks: false,
        remarkValue: "",
        type: "textarea",
        rows: 2,
        label: "DETAILS OF SUCH CHANGES",
        inputName: "detailsChangesManagement",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 117,
        index: 116,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "DOCUMENTS RECEIVED FOR VERIFICATION",
        inputName: "verificationDocumentsManagement",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 22 : GRANT / SUBSIDES
  {
    sectionId: 22,
    heading: "GRANT / SUBSIDES",
    questions: [
      {
        questionId: 118,
        index: 117,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "HAVE RECEIVED ANY GRANTS / SUBSIDIES?",
        inputName: "receivedGrantsSubsidies",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 119,
        index: 118,
        visible: false,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "WHAT IS THE NATURE OF GRANT/SUBSIDY?",
        inputName: "natureGrantsSubsidies",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 120,
        index: 119,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "REFUNDABLE / NOT?",
        inputName: "refundable",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 121,
        index: 120,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "WHAT IS THE ACCOUNTING TREATMENT?",
        inputName: "accountingTreatment",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 122,
        index: 121,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "CONDITIONS WITH GRANTS/ SUBSIDIES",
        inputName: "conditions",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 123,
        index: 122,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "COMPLIANCE WITH THE CONDITIONS",
        inputName: "complianceConditions",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 124,
        index: 123,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "checkbox",
        label: "ACCOUNTING TREATMENT ON THIS",
        inputName: "accountingTreatmentGrants",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },

  // SECTION - 23 : DISPUTES AND ARBITRATION
  {
    sectionId: 23,
    heading: "DISPUTES AND ARBITRATION",
    questions: [
      {
        questionId: 125,
        index: 124,
        visible: true,
        showRemarks: true,
        remarkValue: "",
        type: "checkbox",
        label: "ANY PENDING DISPUTES?",
        inputName: "pendingDisputes",
        value: "",
        showDocumentUpload: false,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
      {
        questionId: 126,
        index: 125,
        visible: true,
        showRemarks: false,
        remarkValue: "",
        type: "text",
        label: "DETAILED RECEIVED FOR VERIFICATION",
        inputName: "detailedVerification",
        value: "",
        showDocumentUpload: true,
        documentLink: "",
        width: "w-full",
        subFields: [],
      },
    ],
  },
];
