import {
  checkListFail,
  checkListRequest,
  checkListSuccess,
  deleteCheckListFail,
  deleteCheckListRequest,
  deleteCheckListSuccess,
  newCheckListFail,
  newCheckListRequest,
  newCheckListSuccess,
  updateCheckListFail,
  updateCheckListRequest,
  updateCheckListSuccess,
} from "../slices/checkListSlice";
import {
  checkListsFail,
  checkListsRequest,
  checkListsSuccess,
} from "../slices/checkListsSlice";
import { apiClient } from "./userAction";

export const getCheckList = (id) => async (dispatch) => {
  try {
    dispatch(checkListRequest());
    // const { data } = await axios.get(`/api/v1/checklist/${id}`);
    const { data } = await apiClient.get(`/api/v1/checklist/${id}`);
    dispatch(checkListSuccess(data));
  } catch (error) {
    //handle error
    dispatch(checkListFail(error.response.data.message));
  }
};

export const getCheckLists = (keyword, currentPage) => async (dispatch) => {
  try {
   
    dispatch(checkListsRequest());
    let link = `/api/v1/checklists?page=${currentPage}`;

    if (keyword) {
      link += `&keyword=${keyword}`;
    }
    // const { data } = await axios.get(link);
    const { data } = await apiClient.get(link);
    dispatch(checkListsSuccess(data));
  } catch (error) {
    //handle error
    dispatch(checkListsFail(error.response.data.message));
  }
};


export const createCheckList = checkListData => async (dispatch) => {
  try {

    dispatch(newCheckListRequest());
    // const { data } = await axios.post("/api/v1/checklist/new", checkListData);
    const { data } = await apiClient.post(
      "/api/v1/checklist/new",
      checkListData
    );
    dispatch(newCheckListSuccess(data));
  } catch (error) {
    dispatch(newCheckListFail(error.response.data.message));
  }
};


export const updateCheckList = (id, checkListData) => async (dispatch) => {
  try {

    dispatch(updateCheckListRequest());
    // const { data } = await axios.put(`/api/v1/checklist/${id}`, checkListData);
    const { data } = await apiClient.put(
      `/api/v1/checklist/${id}`,
      checkListData
    );

    dispatch(updateCheckListSuccess(data));
  } catch (error) {
    dispatch(updateCheckListFail(error.response.data.message));
  }
};


export const deleteCheckList  =  id => async (dispatch) => {

  try {  
      dispatch(deleteCheckListRequest()) 
      // await axios.delete(`/api/v1/checklist/${id}`);
      await apiClient.delete(`/api/v1/checklist/${id}`);
      dispatch(deleteCheckListSuccess())
  } catch (error) {
      //handle error
      dispatch(deleteCheckListFail(error.response.data.message))
  }
  
}

// export const deleteCheckList = id => async (dispatch) => {
//   try {
//       console.log('Dispatching deleteCheckListRequest');
//       dispatch(deleteCheckListRequest());
      
//       console.log(`Attempting to delete checklist with id: ${id}`);
//       const response = await apiClient.delete(`/api/v1/checklist/${id}`);
//       console.log('Delete response:', response);

//       console.log('Dispatching deleteCheckListSuccess');
//       dispatch(deleteCheckListSuccess());
//   } catch (error) {
//       console.error('Delete checklist error:', error);
      
//       const errorMessage = error.response?.data?.message || 'An unknown error occurred';
//       console.log('Dispatching deleteCheckListFail with message:', errorMessage);

//       dispatch(deleteCheckListFail(errorMessage));
//   }
// };
