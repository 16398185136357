import React, { Fragment } from "react";
import Header from "./Header";
import Footer from "./Footer";

function HomeLayout({ children }) {
  return (
    <Fragment>
      <Header />
      <main
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        {children}
      </main>
      <Footer />
    </Fragment>
  );
}

export default HomeLayout;
