import React, { useState, useRef } from "react";
import { X, Upload, AlertCircle, FileText, Download } from "lucide-react";
import {
  setShowDocUpload,
  removeUploadDocumentLink,
} from "../../hooks/createCheckListSlice";
import { useDispatch, useSelector } from "react-redux";
import CButton from "./CButton";
import { uploadDocument } from "../../hooks/createCheckListReducers";

export default function UploadDocumentDialogue() {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);
  const accept = ".png,.jpeg,.jpg,.pdf,.doc,.docx";

  const { docUploadLoading, uploadDocError, uploadDocData } = useSelector(
    (state) => state.createCheckListState
  );

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFile = event.dataTransfer.files[0];
    validateFile(droppedFile);
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    validateFile(selectedFile);
  };

  const validateFile = (selectedFile) => {
    if (!selectedFile) return;

    const validFormats = [
      "image/png",
      "image/jpeg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    if (!validFormats.includes(selectedFile.type)) {
      setError(
        "Invalid file format. Please upload a PNG, JPEG, PDF, DOC, or DOCX."
      );
      return;
    }

    if (selectedFile.size > 10 * 1024 * 1024) {
      setError("File size exceeds 10MB limit.");
      return;
    }

    setFile(selectedFile);
    setError("");
  };

  const handleRemoveFile = () => {
    setFile(null);
    setError("");
  };

  const handleUpload = () => {
    if (!file) return;
    dispatch(uploadDocument({ file: file }));
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">
            {uploadDocData.documentLink
              ? "Download Document"
              : "Upload Document"}
          </h2>
          <button
            onClick={() =>
              dispatch(
                setShowDocUpload({
                  showDialogue: false,
                  uploadDocData: null,
                })
              )
            }
            className="p-1 hover:bg-gray-100 rounded-full"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {uploadDocData.documentLink ? (
          <div>
            <div className="mt-4 p-3 bg-gray-50 rounded-lg flex items-center">
              <FileText className="w-5 h-5 text-blue-500 mr-2" />
              <span className="text-sm text-gray-700 truncate flex-1">
                {uploadDocData.documentName}
              </span>
              <button
                onClick={() =>
                  window.open(uploadDocData.documentLink, "_blank")
                }
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <Download className="w-5 h-5 text-blue-600" />
              </button>
              <button
                onClick={() => dispatch(removeUploadDocumentLink())}
                className="p-1 hover:bg-gray-200 rounded-full"
              >
                <X className="w-4 h-4 text-red-500" />
              </button>
            </div>
          </div>
        ) : (
          <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`border-2 border-dashed rounded-lg p-8 ${
              isDragging ? "border-blue-500 bg-blue-50" : "border-gray-300"
            } ${
              error ? "border-red-500 bg-red-50" : ""
            } transition-colors duration-200`}
          >
            <div className="flex flex-col items-center text-center">
              <Upload
                className={`w-12 h-12 mb-4 ${
                  error ? "text-red-500" : "text-gray-400"
                }`}
              />
              <p className="mb-2 text-sm text-gray-600">
                Drag and drop your file here, or
                <button
                  onClick={() => fileInputRef.current?.click()}
                  className="text-blue-500 hover:text-blue-600 ml-1"
                >
                  browse
                </button>
              </p>
              <p className="text-xs text-gray-500">
                Supported formats: PNG, JPEG, PDF, DOC, DOCX (max 10MB)
              </p>
              {error && (
                <div className="flex items-center mt-2 text-red-600 text-sm">
                  <AlertCircle className="w-4 h-4 mr-1" />
                  {error}
                </div>
              )}
            </div>
            <input
              ref={fileInputRef}
              type="file"
              accept={accept}
              onChange={handleFileSelect}
              className="hidden"
            />
          </div>
        )}

        {file && (
          <div className="mt-4 p-3 bg-gray-50 rounded-lg flex items-center">
            <FileText className="w-5 h-5 text-blue-500 mr-2" />
            <span className="text-sm text-gray-700 truncate flex-1">
              {file.name}
            </span>
            <button
              onClick={handleRemoveFile}
              className="p-1 hover:bg-gray-200 rounded-full"
            >
              <X className="w-4 h-4 text-gray-500" />
            </button>
          </div>
        )}

        {uploadDocError && (
          <div className="mt-2 text-red-600 text-sm">{uploadDocError}</div>
        )}

        {!uploadDocData.documentLink && (
          <div className="mt-6 flex justify-end gap-2">
            <button
              onClick={() =>
                dispatch(
                  setShowDocUpload({
                    showDialogue: false,
                    uploadDocData: null,
                  })
                )
              }
              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
            >
              Cancel
            </button>
            <CButton
              size="md"
              children={"Upload"}
              isLoading={docUploadLoading}
              onClick={handleUpload}
              disabled={!file || docUploadLoading}
            />
          </div>
        )}
      </div>
    </div>
  );
}
