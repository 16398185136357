import { useState, useEffect } from "react";

const PaginationTab = ({
  currentPage: propCurrentPage,
  totalPages,
  onPageClicked,
}) => {
  const [currentPage, setCurrentPage] = useState(propCurrentPage || 1);

  useEffect(() => {
    onPageClicked(currentPage || 1);
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(propCurrentPage || 1);
  }, [propCurrentPage]);

  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  return (
    <div className="p-4 flex flex-wrap justify-end gap-2 w-full">
      <button
        onClick={() => {
          setCurrentPage(currentPage - 1);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        className={`px-3 border rounded ${
          currentPage === 1 ? "text-gray-400" : ""
        } hover:bg-gray-100`}
        disabled={currentPage === 1}
      >
        Prev
      </button>

      {pages.map((index) => (
        <button
          key={index}
          onClick={() => {
            setCurrentPage(index);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          className={`px-3 border rounded ${
            currentPage === index ? "text-gray-400" : ""
          } hover:bg-gray-100`}
          disabled={currentPage === index}
        >
          {index}
        </button>
      ))}

      <button
        onClick={() => {
          setCurrentPage(currentPage + 1);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
        className={`px-3 border rounded ${
          currentPage === totalPages ? "text-gray-400" : ""
        } hover:bg-gray-100`}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default PaginationTab;
