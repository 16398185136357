import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CButton from "../components/common/CButton";
import { useParams, useNavigate } from "react-router-dom";
import { fetchCheckListById } from "../hooks/createCheckListReducers";
import { Loader2 } from "lucide-react";
import {
  getQuestionLabelFromId,
  getQuestionIndexFromId,
  getSectionTitleFromId,
  getSubQuestionLabelFromId,
} from "../hooks/createCheckListActions";

export default function PreviewCheckList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { previewCheckList, fetchCheckListLoading } = useSelector(
    (state) => state.createCheckListState
  );

  useEffect(() => {
    dispatch(fetchCheckListById(id));
  }, []);

  return fetchCheckListLoading ? (
    <div className="flex justify-center items-center h-screen gap-2">
      <Loader2 className="w-8 h-8 text-primaryClr animate-spin" />
      <p className="text-primaryClr mt-3 text-lg font-semibold">Loading...</p>
    </div>
  ) : (
    previewCheckList.checklist && (
      <div className="px-8 py-4 ">
        <h1 class="print-title">Bizpole Audit - 2025</h1>
        <div className="w-full mb-4 flex flex-row justify-end gap-6 no-print">
          <CButton
            onClick={() => {
              navigate(`/edit/${id}`);
            }}
            children={"Edit"}
            variant="outline"
          />
          <CButton
            onClick={() => {
              window.print();
            }}
            children={"Print"}
            classes="bg-green-500"
          />
        </div>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border p-2">Sl.No.</th>
              <th className="border p-2">Question</th>
              <th className="border p-2 w-1/3">Answer</th>
              <th className="border p-2 w-20">File</th>
            </tr>
          </thead>
          <tbody>
            {previewCheckList.checklist.map((section) => (
              <>
                <tr className="bg-gray-200 font-semibold">
                  <td colSpan={5} className="border p-2 text-left">
                    {`${section.sectionId}. ${getSectionTitleFromId(
                      section.sectionId
                    )}`}
                  </td>
                </tr>
                {section.questions
                  .filter((q) => q.visible)
                  .map((q) => (
                    <React.Fragment key={q.questionId}>
                      {/* Main Question */}
                      <tr className="border">
                        <td className="border p-2">
                          {getQuestionIndexFromId(
                            section.sectionId,
                            q.questionId
                          )}
                          .
                        </td>
                        <td className="border p-2">
                          {getQuestionLabelFromId(
                            section.sectionId,
                            q.questionId
                          )}
                        </td>
                        <td className="border p-2">{q.value || "-"}</td>
                        <td className="border p-2 text-center">
                          {q.showDocumentUpload && q.documentLink ? (
                            <a
                              href={q.documentLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 text-sm"
                            >
                              Download
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      {/* Sub Questions (If Exist) */}
                      {q.subFields &&
                        q.subFields.map((subQ, index) => (
                          <tr key={subQ.subFieldId} className="border ">
                            <td className="border p-2 pl-6">
                              {getQuestionIndexFromId(
                                section.sectionId,
                                q.questionId
                              )}
                              . {index + 1}.
                            </td>
                            <td className="border p-2">
                              {getSubQuestionLabelFromId(
                                section.sectionId,
                                q.questionId,
                                subQ.subFieldId
                              )}
                            </td>
                            <td className="border p-2">{subQ.value || "-"}</td>
                            <td className="border p-2 text-center"> - </td>
                          </tr>
                        ))}
                    </React.Fragment>
                  ))}
              </>
            ))}
          </tbody>
        </table>
      </div>
    )
  );
}
