import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchAllCheckLists } from "../hooks/createCheckListReducers";
import { Loader2 } from "lucide-react";
import ActionMenuButton from "../components/common/ActionMenuButton";
import { useNavigate } from "react-router-dom";
import CSearchBar from "../components/common/CSearchBar";
import DeleteConfirmationDialog from "../components/common/DeleteConformationDialogue";
import {
  setDeleteDialogueOpen,
  setDeleteCheckListId,
} from "../hooks/createCheckListSlice";
import PaginationTab from "../components/common/PaginationTab";

export default function DashBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkList,
    fetchCheckListLoading,
    isDeleteDialogueOpen,
    paginationData,
    searchQuery,
  } = useSelector((state) => state.createCheckListState);
  useEffect(() => {
    dispatch(
      fetchAllCheckLists({
        page: 1,
        search: "",
      })
    );
  }, []);

  const handleActionMenuSelect = (action, id) => {
    switch (action) {
      case "view":
        navigate(`/preview/${id}`);
        break;
      case "edit":
        navigate(`/edit/${id}`);
        break;
      case "delete":
        dispatch(setDeleteCheckListId(id));
        dispatch(setDeleteDialogueOpen(true));
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full h-screen flex flex-col items-start justify-start p-4">
      {isDeleteDialogueOpen && <DeleteConfirmationDialog />}
      <div className="flex flex-row justify-between items-center w-full mb-4">
        <h1 className="text-2xl font-bold">DashBoard</h1>
        <CSearchBar
          onSearch={(searchTerm) =>
            dispatch(
              fetchAllCheckLists({
                page: 1,
                search: searchTerm,
              })
            )
          }
        />
      </div>
      <div className="flex flex-col gap-2 mt-2 w-full">
        {fetchCheckListLoading ? (
          <div className="flex justify-center items-center h-screen gap-2">
            <Loader2 className="w-8 h-8 text-primaryClr animate-spin" />
            <p className="text-primaryClr mt-3 text-lg font-semibold">
              Loading...
            </p>
          </div>
        ) : checkList.length === 0 ? (
          <p>No Checklists Available</p>
        ) : (
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr className="bg-gray-200">
                <th className="border border-gray-300 p-2 text-left">
                  Company Name
                </th>
                <th className="border border-gray-300 p-2 text-left">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {checkList.map((checkListItem) => (
                <tr key={checkListItem._id} className="border border-gray-300">
                  <td className="border border-gray-300 p-2">
                    {checkListItem.companyName}
                  </td>
                  <td className="border border-gray-300 p-2 w-20">
                    <ActionMenuButton
                      onselect={handleActionMenuSelect}
                      id={checkListItem._id}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {paginationData && paginationData.totalPages > 1 && (
          <PaginationTab
            currentPage={paginationData.currentPage}
            totalPages={paginationData.totalPages}
            onPageClicked={(page) => {
              dispatch(
                fetchAllCheckLists({
                  page: page,
                  search: searchQuery,
                })
              );
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        )}
      </div>
    </div>
  );
}
