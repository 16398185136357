import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearAuthError, register } from "../../actions/userAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Container, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";




function Register() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [passwordError, setPasswordError] = useState("");
  const dispatch = useDispatch();
  const {  error, isAuthenticated ,user } = useSelector(
    (state) => state.authState
  );
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Reset password error when user types in password or confirm password field
    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (userData.password !== userData.confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    dispatch(register(userData));
    // Add logic to handle form submission (e.g., send data to server)
    console.log(userData);
  };

  useEffect(() => {

    if (isAuthenticated && user && user.role !== "admin") {
      navigate("/");
      return;
    }
  
    if (error) {
      toast.error(error, {
        position: "top-center",
        autoClose: 5000,
        onOpen: () => {
          dispatch(clearAuthError);
        },
      });
    }
  }, [error, dispatch, isAuthenticated, navigate, user]);
  

  return (
    <Container>
      <div className="loginPageStyle row">
        <div className="col-md-4 reg-section reg_style">
          <h3 className="title_head">Already a User?</h3>
          <p>If you already have an account, please login</p>
          <button className="inputBtn" type="submit"       onClick={() => {
              navigate("/login");
            }}>
            Login
          </button>
        </div>
        <div className="col-md-8 loginPageDiv">
          <Form className="inputForm" onSubmit={handleSubmit}>
            <h3 className="title_head">Create New User Account</h3>
            <div className="inputDiv">
              <input
                className="inputField"
                type="text"
                name="name"
                value={userData.name}
                placeholder="Name..."
                onChange={handleChange}
              />
            </div>
            <div className="inputDiv">
              <input
                className="inputField"
                type="email"
                value={userData.email}
                placeholder="Email..."
                onChange={handleChange}
                name="email"
              />
            </div>
            <div className="inputDiv">
              <input
                className="inputField"
                type="password"
                value={userData.password}
                name="password"
                placeholder="Password.."
                onChange={handleChange}
              />
            </div>
            <div className="inputDiv">
              {passwordError && (
                <Form.Text className="text-danger">{passwordError}</Form.Text>
              )}
              <input
                className="inputField"
                type={showPassword ? "text" : "password"}
                value={userData.confirmPassword}
                name="confirmPassword"
                placeholder="Confirm Password.."
                onChange={handleChange}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                onClick={togglePasswordVisibility}
                className="eyeIcon"
              />
            </div>
            <button className="inputBtn" type="submit">
              SIGN IN
            </button>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default Register;
