import React from "react";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";
import { MoreVertical, Eye, Pencil, Trash2 } from "lucide-react";
import { useSelector } from "react-redux";

export default function ActionMenuButton({ onselect, id }) {
  const { user } = useSelector((state) => state.authState);
  return (
    <Menu
      menuButton={
        <MenuButton className="p-2">
          <MoreVertical size={20} />
        </MenuButton>
      }
      transition
    >
      <MenuItem
        onClick={() => onselect("view", id)}
        className="flex items-center gap-2 "
      >
        <Eye className="text-blue-600" size={16} />
        View
      </MenuItem>
      <MenuItem
        onClick={() => onselect("edit", id)}
        className="flex items-center gap-2 "
      >
        <Pencil className="text-green-500" size={16} />
        Edit
      </MenuItem>
      {user && user.role === "admin" ? (
        <MenuItem
          onClick={() => onselect("delete", id)}
          className="flex items-center gap-2 "
        >
          <Trash2 className="text-red-500" size={16} />
          Delete
        </MenuItem>
      ) : (
        ""
      )}
    </Menu>
  );
}
