import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk"; // Import thunk as a named export
import checkListsReducer from "./slices/checkListsSlice";
import checkListReducer from "./slices/checkListSlice";
import createCheckListSlice from "./hooks/createCheckListSlice";
import authReducer from "./slices/authSlice";

const reducer = combineReducers({
  checkListsState: checkListsReducer,
  checkListState: checkListReducer,
  authState: authReducer,
  createCheckListState: createCheckListSlice,
});

const middleware = (getDefaultMiddleware) => [
  ...getDefaultMiddleware(),
  thunk, // Add thunk middleware to the store
];

const store = configureStore({
  reducer: reducer,
  middleware: middleware, // Add thunk middleware to the store
});

export default store;
