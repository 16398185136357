import { Search } from "lucide-react";
import { setSearchQuery } from "../../hooks/createCheckListSlice";
import { useSelector, useDispatch } from "react-redux";

export default function CSearchBar({ onSearch }) {
  const dispatch = useDispatch();

  const { searchQuery } = useSelector((state) => state.createCheckListState);

  const handleSearch = () => {
    if (onSearch) {
      onSearch(searchQuery);
    }
  };

  return (
    <div className="h-10 flex items-center border border-gray-300 rounded-lg p-2 w-full max-w-sm">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => {
          dispatch(setSearchQuery(e.target.value));
        }}
        className="flex-1 px-3 py-2 outline-none bg-transparent"
      />
      <button
        onClick={handleSearch}
        className="p-2 text-gray-600 hover:text-gray-900 transition"
      >
        <Search className="w-5 h-5" />
      </button>
    </div>
  );
}
