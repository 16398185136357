import { useEffect, useRef } from "react";
import { setDeleteDialogueOpen } from "../../hooks/createCheckListSlice";
import { useSelector, useDispatch } from "react-redux";
import { deleteCheckList } from "../../hooks/createCheckListReducers";
import { toast } from "react-toastify";

export default function DeleteConfirmationDialog() {
  const dialogRef = useRef(null);
  const dispatch = useDispatch();
  const { isDeleteDialogueOpen, deleteCheckListId } = useSelector(
    (state) => state.createCheckListState
  );

  const { user } = useSelector((state) => state.authState);

  // Close dialog when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dialogRef.current && !dialogRef.current.contains(event.target)) {
        dispatch(setDeleteDialogueOpen(false));
      }
    };
    if (isDeleteDialogueOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDeleteDialogueOpen]);

  if (!isDeleteDialogueOpen) return null;

  const onDelete = async () => {
    if (!user._id) {
      toast.error("User ID is missing.Please login again.");
      return;
    }
    const res = await dispatch(
      deleteCheckList({ id: deleteCheckListId, updatedBy: user._id })
    );
    console.log("res", res);
    dispatch(setDeleteDialogueOpen(false));
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div ref={dialogRef} className="bg-white p-4 rounded-lg shadow-lg w-96">
        <h2 className="text-lg font-semibold text-gray-800">
          Delete Confirmation
        </h2>
        <p className="text-gray-600 mt-2">
          Are you sure you want to delete this checklist?
        </p>
        <div className="flex justify-end mt-4 space-x-2">
          <button
            onClick={() => dispatch(setDeleteDialogueOpen(false))}
            className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={onDelete}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}
